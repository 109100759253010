// Description: This file contains all application-wide Sass constants.


// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


/* COLORS */

// White color for the application.
$white: #fff;

// Base background color.
// @type Color
$background-base-color: #efefef;

// Side navigation background
// @type Color
$background-side-nav: #fbfbfb;

// Base font color.
// @type Color
$font-base-color: #2a2a2a;

// Medium gray color
$medium-gray-color: #666;

// Light gray color
$light-gray-color: #c1c1c1;
$gray-icon-bg: #666;
$med-gray: #555;
$paleGray: #dbdbdb;
$medGrey: #bfbfbf;
$darkGrey: #999;
$mega-menu-bg: #f6f6f6;
$bootstrap-page-header-border: #eee;
$costing-row-bg:#f6f6f6;

// Gray colors
$icon-gray: #333;
$probability-slider-gray: #ededed;
$lighter-gray: #f2f2f2;
$superPaleGray: #f8f7f7;
$table-alternate-color: #fafafa;
$bootstrap-text-muted-color: #777;

// Main primary color (Refresh blue).
// @type Color
// $primary-color: #0378b9;
$primary-color: #0061A3;


// Dark refresh blue
$dark-blue: #3e8acc;
$hover-blue: #286090;
$border-blue: #2e6da4;
$hover-border-blue: #204d74;

// *** TODO - Move nav styles to the input style class.
$input-blue: #dce1f3;
$input-red: #f2dede;

// Base black color.
// @type Color
$base-color-black: #000;

// Base green color.
// @type Color
$base-color-green: #56ac43;

// Base orange color.
// @type Color
$base-color-orange: #f19003;

// Base red color.
// @type Color
$base-color-red: #e5343a;
// Dark refresh red.
$dark-red: #bb4640;
$pale-base-red: #d68c92;

// Pale blue color.
$pale-blue: #d0e7f7;

// Gray border color.
$border-color: #c9c9c9;
$picture-border-color: rgb(220, 220, 220);
$picture-border-color-hover: rgb(120, 120, 120);

// Disable gray background color.
$background-disable-color: #c9c9c9;

// Warning colors inherited from Bootstrap CSS 3.*.*.
$warning-bg-color: #f1edd7;
$warning-border-color: #faebcc;
$warning-font-color: #f28e08;

/* FONTS */

// Regular font family
// @type List
// $font-stack: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, sans-serif;
// $font-stack: 'Montserrat', sans-serif;
$font-stack: 'Open Sans', sans-serif;
// Use this for the Material theme typography - the above in a single string
// - To avoid SASS DEPRECATED warnings
// $font-stack-material: 'Montserrat', sans-serif;
$font-stack-material: 'Open Sans', sans-serif;


// Alignment
$center:center;
$left:left;
$right:right;



/* Application font size and font weight. */
// Body Bootstrap paragraph (16px)
$font-base-size: 16px;
$font-base-weight: 400;
$line-height: 1.375em;
$font-bold-weight: 700;

/* MEDIA QUERIES */

// Standard media query sizes.
$desktop: 1200px;
$tablet-large: 1024px;
$tablet: 768px;
$mobile-large: 640px;
$mobile: 480px;
$mobile-small: 300px;

// Container's maximum width
// @type Length
$max-width-fluid-grid: 1180px !default;

/* MEDIA QUERIES */

// Standard media query sizes.
$desktop: 1200px;
$tablet: 768px;
$mobile-large: 640px;
$mobile: 480px;
$mobile-small: 300px;

// Angular Flex layout responsive breakpoints
// @see https://github.com/angular/flex-layout/wiki/Responsive-API#mediaqueries-and-aliases
$fx-lt-sm: 599px;   // 'screen and (max-width: 599px)'
$fx-lt-md: 959px;   // 'screen and (max-width: 959px)'
$fx-lt-lg: 1279px;  // 'screen and (max-width: 1279px)'
$fx-lt-xl: 1919px;  // 'screen and (max-width: 1919px)'
$fx-gt-xs: 600px;   // 'screen and (min-width: 600px)'
$fx-gt-sm: 960px;   // 'screen and (min-width: 960px)'
$fx-gt-md: 1280px;  // 'screen and (min-width: 1280px)'
$fx-gt-lg: 1920px;  // 'screen and (min-width: 1920px)'

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
// @see {mixin} respond-to
$breakpoints: (
  'small': (min-width: 320px),
  'medium': (min-width: 768px),
  'large': (min-width: 1024px),
) !default;

// Breakpoints defined by Bootstrap (v3.3)
// @see https://getbootstrap.com/docs/3.3/css/#grid-options
// Note: these are the MIN widths for these grid options
$breakpoints-bootstrap: (
  'xs': 576px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
) !default;

// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN, $base-url: 'http://cdn.example.com/assets/';
$base-url: '/resources/assets/' !default;

// *** TODO - Move nav styles to the breadcrumb style class. ***//
// Breadcrumb variables.
$breadcrumb-padding: 5px;
$breadcrumb-height: 20px + ($breadcrumb-padding * 2);
$fix-add-padding: 10px;

// Control blue.
$refresh-blue: #0378b9;
$refresh-blue: #0061A3;

// Gray border color.
$border-color: #c9c9c9;

// Off canvas variables for Contact List.
$offcanvasSpeed: .5s;
$offcanvasButtonWidth: 14px;
$offcanvasMenuWidth: 300px;

// Colour of background on hover
$hover-shade: #ebebeb;

// Color for inline-editable "underline" border
$inline-editable-underline: rgba(0, 0, 0, .3);

// Copied from Bootstrap styling.
// https://getbootstrap.com/docs/3.3/components/#alerts

// Bootstrap shared alert variables.
$bootstrap-alert-padding: 15px;
$bootstrap-alert-border: 1px solid transparent;
$bootstrap-alert-border-radius: 4px;
$bootstrap-alert-bottom-margin: 20px;

// Bootstrap warning alert colors.
$bootstrap-alert-warning-color: #8a6d3b;
$bootstrap-alert-warning-background-color: #fcf8e3;
$bootstrap-alert-warning-border-color: #faebcc;

// Bootstrap danger alert colors.
$bootstrap-alert-danger-color: #a94442;
$bootstrap-alert-danger-background-color: #f2dede;
$bootstrap-alert-danger-border-color: #ebccd1;
$bootstrap-alert-danger-alert-link: #843534;

// Bootstrap info alert colors.
$bootstrap-alert-info-color: #31708f;
$bootstrap-alert-info-background-color: #d9edf7;
$bootstrap-alert-info-border-color: #bce8f1;

// Bootstrap success alert colors.
$bootstrap-alert-success-color: #3c763d;
$bootstrap-alert-success-background-color: #dff0d8;
$bootstrap-alert-success-border-color: #d6e9c6;



$full-width: 100%;
$pointer:pointer;
$dinline-flex:inline-flex;